<!-- 处理留言编辑 -->
<template>
    <div class="EditStudentMsg">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">处理留言编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" id="form-1" class="" inline label-width="150px">
            <el-form-item label="标题">
                <el-input v-model="form.heading" placeholder="" class="textArea-width"></el-input>
            </el-form-item>
            <el-form-item label="发布日期">
                <el-date-picker
                      v-model="form.issueDate"
                      type="date"
                      placeholder="请选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="发布时间">
                <el-time-picker
                    v-model="form.issueTime"
                    :picker-options="{
                      selectableRange: '0:0:00 - 23:59:59'
                    }"
                    placeholder="请选择时间">
                  </el-time-picker>
            </el-form-item>
            <el-form-item label="选择客户">
                <el-select v-model="form.customer" placeholder="请选择">
                    <el-option
                      v-for="item in customerOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                <!-- <el-input v-model="form.customer" placeholder=""></el-input> -->
            </el-form-item>
            <el-form-item label="留言类型">
                <div style="width: 300px;">
                    <el-radio-group v-model="form.msgType">
                        <el-radio :label="1">好评</el-radio>
                        <el-radio :label="2">中评</el-radio>
                        <el-radio :label="3">差评</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="资料发布">
                <div style="width: 300px;">
                    <el-radio-group v-model="form.publish">
                        <el-radio :label="1">发布</el-radio>
                        <el-radio :label="2">归档</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="详细内容">
                <el-input v-model="form.detailed" :rows="5"
                 type="textarea" class="textArea-width" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                  heading:"",//标题
                  issueDate:"",//发布日期
                  issueTime:"",//发布时间
                  customer:"",//选择客户
                  customerOpt:[],//客户选项
                  msgType:1,//留言类型 
                  publish:1,//资料发布
                  detailed:""//详细内容
               } 
            }
        },
        created() {
            //客户选项
            // this.$request({
            //     url: "",
            //     method: "POST",
            //     data: {
            //         page: 1,
            //         limit: 100
            //     }
            // })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.$refs[form].resetFields();
                this.goBack()
            },
        }
    }
</script>

<style scoped="scoped">
    .EditStudentMsg{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    /* 单选按钮 */
     #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
    
    }
    
    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        margin-top: 40px;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
